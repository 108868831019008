import { MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import Slider from 'react-infinite-logo-slider'
import {React, useEffect} from 'react'
import Akershus_Fylkeskommune from '../../assets/akershus_logo.png'
import Cta from '../cta/Cta'
import './Brand.css'

const Brand = () => {

    useEffect(() => {
                var copy = document.querySelector(".logos-slide").cloneNode(true);
                document.querySelector(".logos").appendChild(copy);
    
      return () => {
      }
    }, [])
    
    return (
        <MDBContainer fluid className='text-center bg-dark bg-opacity-50 p-0 glass-white'>
            <div class="logos">
                <div class="logos-slide">
                                <img src="https://img.logoipsum.com/311.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
                                <img src="https://img.logoipsum.com/317.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
                                <img src="https://img.logoipsum.com/280.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
                                <img src="https://img.logoipsum.com/220.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
                                <img src="https://img.logoipsum.com/214.svg" alt="" className='brand_logo' style={{ height: '40px' }} />
                </div>
            </div>
            <hr className="hr hr-blurry" />
            <Cta />
        </MDBContainer>
        
    )
}

export default Brand