import { MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import ContactForm from '../contactForm/ContactForm'
import './Cta.css'

const Cta = () => {
  return (
    <div class="padding-1">
    <MDBContainer fluid className='text-light'>
        <MDBContainer className='' id='CTA_wrapper'>
              <MDBRow id='CTA_Row' className='p-0'>
                <MDBCol md='7' className='justify-content-center text-start d-flex flex-column' id='CTA-text_wrapper'>
                    <MDBTypography variant='h3' className='text-uppercase display-6 fw-bold'>
                        Det <span className='text-decoration-underline'>beste</span> alternativet <br />
                          <span className='text-rgb'>for din bedrift</span>
                    </MDBTypography>
                    <MDBTypography variant='p' className='lead w-75 my-2' id='CTA_text-p'>
                          Når du velger oss, velger du mer enn bare et IT-system – du velger en partner som forstår dine behov. Våre løsninger er utviklet for å være fleksible, skalerbare og tilpasset nettopp din bedrift. Med rask implementering, brukervennlige grensesnitt og uovertruffen kundesupport, sikrer vi at du får den beste opplevelsen fra dag én. Vi er her for å hjelpe din bedrift med å vokse og lykkes i en digital verden.
                    </MDBTypography>
                  </MDBCol>
                <MDBCol md='5' className='rounded my-3 padding-4' id='CTA-form_wrapper'>
            <ContactForm />
                  </MDBCol>
            </MDBRow>
        </MDBContainer>
    </MDBContainer>
    </div>
  )
}

export default Cta