import { MDBContainer, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import { Parallax } from 'react-parallax'
import Brand from '../../components/brand/Brand'
import Pricing from '../pricing/Pricing'

const Customers = () => {
  return (
    <Parallax className='bg-dark bg-opacity-50' strength={200} bgImage='https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=2672&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'>
      <div className='padding-5'>
        <MDBContainer className='text-center padding-5'>
        <MDBTypography variant='h2' color='light'>
            Våre kunder
        </MDBTypography>
        <MDBTypography variant='p' className='text-secondary mt-3'>
          Vi er stolte over å ha flere store og anerkjente aktører blant våre kunder, som for eksempel Akershus Fylkeskommune. De, og mange andre, stoler på våre systemer for å effektivisere og forbedre sin IT-drift. Våre løsninger er skreddersydd for å møte de høye kravene til både offentlige og private virksomheter, og vi leverer alltid kvalitet, pålitelighet og førsteklasses support.
        </MDBTypography>
          </MDBContainer>
        <Brand />
    </div>
        <Pricing />
    </Parallax>
  )
}

export default Customers