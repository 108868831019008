import React from 'react';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import Hero from './container/hero/Hero';
import Navbar from './container/navbar/Navbar';
import Customers from './container/customers/Customers';
import Features from './container/features/Features';
import Footer from './container/footer/Footer';
import Pricing from './container/pricing/Pricing';

function App() {
  return (
    <>
    <Navbar />
    <Hero />
    <Features />
    <Customers />
    <Footer />
    </>
  );
}

export default App;
