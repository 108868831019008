import React from 'react'
import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; // Laster den slanke versjonen for ytelsesfordeler
import './Particles_effect.css'


const Particles_effect = () => {

    const [init, setInit] = useState(false);

    // Last inn partikkel motoren kun én gang
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    // Definer partikkelinnstillinger med useMemo for optimalisering
    const particlesOptions = useMemo(() => ({
        fullScreen: { enable: false },
        particles: {
            number: {
                value: 120,
                density: {
                    enable: true,
                    value_area: 800,
                },
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 0.5,
                random: true,
            },
            size: {
                value: 2,
                random: true,
            },
            move: {
                enable: true,
                speed: 0.5,
                direction: "none",
                random: true,
                straight: false,
                outMode: "out",
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: false,
                    mode: "repulse",
                },
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                repulse: {
                    distance: 100,
                },
                push: {
                    quantity: 4,
                },
            },
        },
    }), []);


  return (
    <>
          {init && (
              <Particles
                  id="tsparticles"
                  options={particlesOptions}
              />
          )}
    </>
  )
}

export default Particles_effect