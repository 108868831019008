import React from 'react';
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon,
    MDBBtn
} from 'mdb-react-ui-kit';
import './Footer.css'

const Footer = () => {
  return (
      <MDBFooter className='text-center text-light footer_bg'>
          <MDBContainer className='pt-4'>
              <section className='mb-4'>
                  <MDBBtn
                      rippleColor="light"
                      color='light'
                      floating
                      size="lg"
                      className='text-white m-2'
                      href='#!'
                      role='button'
                      outline
                  >
                      <MDBIcon fab className='fab fa-facebook-f' />
                  </MDBBtn>

                  <MDBBtn
                      rippleColor="light"
                      color='light'
                      floating
                      size="lg"
                      className='text-white m-2'
                      href='#!'
                      role='button'
                      outline
                  >
                      <MDBIcon fab className='fa-instagram' />
                  </MDBBtn>

                  <MDBBtn
                      rippleColor="light"
                      color='light'
                      floating
                      size="lg"
                      className='text-white m-2'
                      href='#!'
                      role='button'
                      outline
                  >
                      <MDBIcon fab className='fa-linkedin' />
                  </MDBBtn>
              </section>
          </MDBContainer>

          <div className='text-center text-light p-3'>
              © 2025 Copyright - <a className='text-light' href='https://Dencker.no/'> Dencker.no</a>
          </div>
      </MDBFooter>
  )
}

export default Footer