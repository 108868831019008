import { MDBIcon, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'

const HeroStats = () => {
  return (
    <div className='d-flex w-100 align-items-end h-50'>
        <div className='flex-column d-flex text-center justify-content-center align-items-center mx-auto' style={{width: '30%'}}>
        <MDBIcon fas icon="desktop" color='primary' className='text-rgb' size='3x'/> 
        <MDBTypography variant='h2' className='mt-4 fw-bold text-rgb' color='primary'>
                30 +
            </MDBTypography>
        <MDBTypography variant='h5' className=' text-light'>
                  NETTSIDER
            </MDBTypography>
        </div>
        <div className="vr"></div>
        <div className='flex-column d-flex text-center justify-content-center align-items-center mx-auto' style={{width: '30%'}}>
              <MDBIcon fas icon="users" color='primary' className='text-rgb' size='3x'/> 
        <MDBTypography variant='h2' className='mt-4 fw-bold text-rgb' color='primary'>
            500 +
            </MDBTypography>
        <MDBTypography variant='h5' className='text-light'>
            BRUKERE
            </MDBTypography>
        </div>
    </div>
  )
}

export default HeroStats