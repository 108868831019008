import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTypography, MDBBtn, MDBIcon, MDBCardHeader } from 'mdb-react-ui-kit';
import React from 'react';
import './Pricing.css';
import Particles from "@tsparticles/react";
import { loadFull } from "tsparticles";

const Pricing = () => {

    const particlesInit = async (main) => {
        console.log(main); // For å se om engine blir initialisert
        await loadFull(main);
    };


    const particlesOptions = {
        fullScreen: { enable: false },
        particles: {
            number: {
                value: 100,
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 0.5,
            },
            size: {
                value: { min: 1, max: 5 },
            },
            move: {
                enable: true,
                speed: 2,
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                repulse: {
                    distance: 100,
                },
                push: {
                    quantity: 4,
                },
            },
        },
        retina_detect: true,
    };

    return (
        <MDBContainer fluid className="py-5 text-light position-relative" style={{minHeight: '50vh'}}>
            <Particles id="tsparticles" init={particlesInit} options={particlesOptions} className="particles" />
            <MDBRow className="d-flex justify-content-center h-100">
                <MDBCol md="5" sm='9' lg='5' xl='5' xxl='3' className="my-2 p-0 mx-2 bg-dark bg-opacity-75 glass-white">
                    <MDBCard className="text-dark card-bg h-100">
                        <div className="w-100 bg-dark bg-opacity-50 d-flex flex-column">
                            <MDBIcon fas icon="rocket" size="2x" className="text-rgb my-auto d-flex mx-auto p-3" />
                            <MDBTypography tag="h5" className="text-center mt-2 mx-auto text bg-none-uppercase fw-bold fs-3 text-rgb">DEMO </MDBTypography>
                        </div>
                        <MDBCardBody className='d-flex justify-content-center flex-column bg-dark bg-opacity-50'>
                            <ul className="list-unstyled mb-4">
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 1</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 2</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 3</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="times-circle" size='lg' className="me-2 text-secondary" /> Funksjon 4</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="times-circle" size='lg' className="me-2 text-secondary" /> Funksjon 5</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="times-circle" size='lg' className="me-2 text-secondary" /> Funksjon 6</li>
                            </ul>
                            <MDBBtn block color="primary">Gratis demo</MDBBtn>
                            <MDBBtn block outline color="light" className="mt-2">Les mer</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol md="5" sm='9' lg='5' xl='5' xxl='3' className="my-2 p-0 mx-2 bg-dark bg-opacity-75 glass-white">
                    <MDBCard className="text-dark card-bg h-100">
                        <div className="w-100 bg-dark bg-opacity-50 d-flex flex-column">
                                <MDBIcon fas icon="rocket" size="2x" className="text-rgb my-auto d-flex mx-auto p-3" />
                            <MDBTypography tag="h5" className="text-center mt-2 mx-auto text bg-none-uppercase fw-bold fs-3 text-rgb">LISENS</MDBTypography>
                        </div>
                        <MDBCardBody className='d-flex justify-content-center flex-column bg-dark bg-opacity-50'>
                            <ul className="list-unstyled mb-4">
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 1</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 2</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 3</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 4</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="times-circle" size='lg' className="me-2 text-secondary" /> Funksjon 5</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="times-circle" size='lg' className="me-2 text-secondary" /> Funksjon 6</li>
                            </ul>
                            <MDBBtn block color="primary">Bestill nå</MDBBtn>
                            <MDBBtn block outline color="light" className="mt-2">Les mer</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol md="5" sm='9' lg='5' xl='5' xxl='3' className="my-2 p-0 mx-2 bg-dark bg-opacity-75 glass-white">
                    <MDBCard className="text-dark card-bg h-100">
                        <div className="w-100 bg-dark bg-opacity-50 d-flex flex-column">
                            <MDBIcon fas icon="rocket" size="2x" className="text-rgb my-auto d-flex mx-auto p-3" />
                            <MDBTypography tag="h5" className="text-center mt-2 mx-auto text bg-none-uppercase fw-bold fs-3 text-rgb">FULL PAKKE</MDBTypography>
                        </div>
                            <MDBCardBody className='d-flex justify-content-center flex-column bg-dark bg-opacity-50'>
                            <ul className="list-unstyled mb-4">
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 1</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 2</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 3</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 4</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 5</li>
                                <li className='d-flex align-items-center my-2 text-light'><MDBIcon fas icon="check-circle" size='lg' className="me-2 text-success" /> Funksjon 6</li>
                            </ul>
                            <MDBBtn block color="primary">Bestill nå</MDBBtn>
                            <MDBBtn block outline color="light" className="mt-2">Les mer</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default Pricing;
