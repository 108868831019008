import React from 'react';
import { Parallax } from 'react-parallax';
import Hero from '../../container/hero/Hero';
import './ParallaxSection.css';
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import HeroStats from '../../components/heroStats/HeroStats'
import Rocket from './images/rocket.png'
import Particles_effect from '../particles_effect/Particles_effect';
import BluePlanet from '../../assets/blue-planet.webp'
import AlienSpaceship from '../../assets/alienSpaceship.png'
import GrayPlanet from '../../assets/planet-gray.png'


const ParallaxSection = () => {
    return (
        <Parallax
            bgImage="https://images.unsplash.com/photo-1457365050282-c53d772ef8b2?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            strength={250}
            blur={{ min: -5, max: 5 }}
            style={{ pointerEvents: 'none', position: 'absolute', height: '100%', width: '100%' }}
        >
            <div className="parallax-content">
                <Particles_effect />
            </div>
            <div className="parallax-content" style={{ height: '0vh', pointerEvents: 'none', top: '100px' }}>
                <Parallax bgImage={Rocket} strength={-1200} className="position-absolute rocket" style={{ top: '0vh', height: '120vh', transform: 'rotate(45deg)', pointerEvents: 'none', zIndex: '1' }} bgImageStyle={{ height: '500px', maxWidth: '400px', opacity: '1', top: '142vh', pointerEvents: 'none', zIndex: '1', position:'absolute' }}>
                    <div className="" style={{
                        height: '200px', width: '200px', position: 'relative', top: '200px', top: '100vh'
                    }}>
                    </div>
                </Parallax>
                <Parallax bgImage={AlienSpaceship} strength={-1200} className="position-absolute rocket" style={{ top: '0vh', height: '170vh', transform: 'rotate(280deg)', pointerEvents: 'none', zIndex: '0' }} bgImageStyle={{ height: '150px', maxWidth: '200px', opacity: '1', top: '181vh', pointerEvents: 'none', zIndex: '1', position:'absolute' }}>
                    <div className="" style={{
                        height: '200px', width: '200px', position: 'relative', top: '200px', top: '100vh'
                    }}>
                    </div>
                </Parallax>
                <Parallax bgImage={BluePlanet} strength={900} className="position-absolute planet" style={{ top: '0vh', width: '100%', pointerEvents: 'none', transform: 'rotate(45deg)', zIndex: '0' }} bgImageStyle={{ height: '100px', maxWidth: '800px', opacity: '1', top: '2vh', pointerEvents: 'none', zIndex: '2', left: '40%' }}>
                    <div className="" style={{
                        height: '800px', width: '800px', position: 'relative', top: '200px', top: '100vh'
                    }}>
                    </div>
                </Parallax>
                <Parallax bgImage={GrayPlanet} strength={100} className="position-absolute planet" style={{ top: '0vh', width: '100%', height: '900px', pointerEvents: 'none', transform: 'rotate(75deg)', zIndex: '0' }} bgImageStyle={{ height: '300px', maxWidth: '300px', opacity: '1', top: '0vh', pointerEvents: 'none', zIndex: '1', left: '80%' }}>
                    <div className="" style={{
                        height: '800px', width: '800px', position: 'relative', top: '200px', top: '100vh'
                    }}>
                    </div>
                </Parallax>
            </div>
        </Parallax>
    );
};

export default ParallaxSection;
