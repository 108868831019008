import React, { useState } from 'react';
import './Navbar.css'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
} from 'mdb-react-ui-kit';

import Logo from '../../assets/logo.png'

const Navbar = () => {
    const [openBasic, setOpenBasic] = useState(false);

  return (
      <MDBNavbar expand='lg' className='p-0 nav-wrapper m-0 w-100 text-light'>
          <MDBContainer fluid className='nav-bg' style={{ maxWidth: '992px'}}>
              <MDBNavbarToggler
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                  onClick={() => setOpenBasic(!openBasic)}
              >
                  <MDBIcon icon='bars' fas color='light' />
              </MDBNavbarToggler>

<div className="w-100" style={{display: 'contents'}}>
              <MDBNavbarBrand href='#'>
                <img src={Logo} alt="" height="64px" />
              </MDBNavbarBrand>

              <MDBCollapse navbar open={openBasic}>
                  <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-center'>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' className='text-light' href='#'>
                              HJEM
                          </MDBNavbarLink>
                      </MDBNavbarItem>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' className='text-light' href='#'>
                              REFERANSER
                          </MDBNavbarLink>
                      </MDBNavbarItem>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' className='text-light' href='#'>
                              OM OSS
                          </MDBNavbarLink>
                      </MDBNavbarItem>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' className='text-light' href='#'>
                              PRISER
                          </MDBNavbarLink>
                      </MDBNavbarItem>
                      <MDBNavbarItem>
                          <MDBNavbarLink active aria-current='page' className='text-light' href='#'>
                              KONTAKT
                          </MDBNavbarLink>
                      </MDBNavbarItem>

                      <MDBNavbarItem>
                          <MDBDropdown>
                              <MDBDropdownToggle tag='a' className='nav-link text-light' role='button'>
                                  SYSTEMER
                              </MDBDropdownToggle>
                              <MDBDropdownMenu dark className=''>
                                  <MDBDropdownItem dark link>SELVHJELP</MDBDropdownItem>
                                  <MDBDropdownItem link>Another action</MDBDropdownItem>
                                  <MDBDropdownItem link>Something else here</MDBDropdownItem>
                              </MDBDropdownMenu>
                          </MDBDropdown>
                      </MDBNavbarItem>
                  </MDBNavbarNav>
              </MDBCollapse>
              </div>
          </MDBContainer>
      </MDBNavbar>
  )
}

export default Navbar