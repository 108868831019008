import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import HeroStats from '../../components/heroStats/HeroStats'
import ParallaxSection from '../../components/parallaxSection/ParallaxSection'
import './Hero.css'

const Hero = () => {
  return (
    <div style={{ minHeight: '100vh' }} className='h-auto position-relative'>
      <ParallaxSection className="h-auto" />
    <MDBContainer fluid id="hero_wrapper" className='p-5 position-relative text-light'>
            <MDBRow className='mt-5 flex-column h-100' id='hero_row'>
          <MDBCol md='8' className='text-start d-flex flex-column justify-content-center w-100 h-75'>
                    <MDBTypography tag='bold' variant='h1' className='fw-bold display-1 text-uppercase' style={{zIndex: '10'}}>
                          Fyr opp bedriften med <br /> <span className='text-rgb'>smarte systemer!</span>
                    </MDBTypography>
            <MDBTypography tag='h5' className='fw-normal mt-3 hero_introText' style={{ zIndex: '10' }}>
              Våre innovative løsninger er laget for å <span className='fw-bold text-primary-gradient' style={{ zIndex: '10' }}>effektivisere, automatisere og gi IT-avdelingen din et løft.</span> 🚀
                    </MDBTypography>
            <MDBTypography tag='h5' className='fw-normal hero_introText' style={{ zIndex: '10' }}>
                          La dine systemer gjøre jobben, slik at teamet ditt kan fokusere på det som virkelig teller.
                    </MDBTypography>
                          
                    <MDBRow className='justify-content-left flex-row mt-3 position-relative hero_buttons' style={{zIndex: '99'}}>
              <MDBBtn color='primary' className='m-2 d-flex w-auto' style={{ zIndex: '10' }}>
                            Våre løsninger
                        </MDBBtn>
              <MDBBtn color='light' outline className='m-2 text-light d-flex w-auto' style={{ zIndex: '10' }}>Kontakt oss</MDBBtn>
                    </MDBRow>
                </MDBCol>
          <MDBCol md='4' className='d-flex align-items-center w-100 h-25'>
            <div id="Hero-stats_wrapper" className='h-100 w-100 d-flex align-items-end mb-5' style={{ zIndex: '10' }}>
              <HeroStats />
            </div>
                </MDBCol>
            </MDBRow>
    </MDBContainer>
    </div>
  )
}

export default Hero